import { useTranslator } from "../../../../context/TranslatorContext";
import { useOrderManagerContext } from "../../context";
import { Container, Modal, Title, Button, Backdrop } from "./style";
import SelectOrderRoute from "./selectOrderRoute";
import GeneratedRoute from "./generatedRoute";
import { toast } from "react-toastify";
import { api } from "../../../../services/api";
import { useAuth } from "../../../../context/AuthContext";
import { XCloseButton } from "../../../../components/ui/xCloseButton";

export function CalculateRoute() {
	const { token } = useAuth();
	const { getText } = useTranslator();
	const {
		showCalculateRoutes,
		setShowCalculateRoutes,
		calculateRouteStep,
		setCalculateRouteStep,
		routeMapIsLoading,
		setRouteMapIsLoading,
		orderRouterSelected,
		selectedBusiness,
		setRouteMapGenerated,
		setRouteMapRoutes,
		setSelectedBusiness,
		setOrderRouterSelected,
		companies,
	} = useOrderManagerContext();

	const isNotValidToGenerate = () => {
		if (selectedBusiness === "all") return false;
		if (
			orderRouterSelected.filter((item) => String(item.cdEmpresa) === selectedBusiness)
				.length === 0
		) {
			toast.error(getText("order-manager.calculate-route.toast.no-orders-selected"));
			return true;
		}
		return false;
	};
	const handleGenerateRoute = async () => {
		try {
			if (isNotValidToGenerate()) return;
			setRouteMapIsLoading(true);
			setCalculateRouteStep(2);
			const body = {
				cdEmpresa: selectedBusiness === "all" ? companies[0].cdEmpresa : selectedBusiness,
				addresses: orderRouterSelected,
			};
			const { data: request } = await api.post(
				`/v1/getPathOfRoutes/${selectedBusiness === "all" ? companies[0].cdEmpresa : selectedBusiness}`,
				body,
				{
					headers: { token: token },
				},
			);
			setRouteMapGenerated(request.body.mapsRouteLink);
			setRouteMapRoutes(request.body.responseArray);
		} catch (error) {
			toast.error(getText("order-manager.calculate-route.toast.error-generate"));
		} finally {
			setRouteMapIsLoading(false);
		}
	};

	const handleOnClose = () => {
		setSelectedBusiness("all");
		setOrderRouterSelected([]);
		setCalculateRouteStep(1);
		setRouteMapGenerated("");
		setRouteMapRoutes([]);
		setRouteMapIsLoading(false);
		setShowCalculateRoutes(false);
	};

	return showCalculateRoutes ? (
		<Container>
			<Modal className="scroll">
				<Title>{getText("order-manager.calculate-route.modal-title")}</Title>

				{calculateRouteStep === 1 && <SelectOrderRoute />}
				{calculateRouteStep === 2 && <GeneratedRoute />}

				{calculateRouteStep === 1 && (
					<Button onClick={() => handleGenerateRoute()}>
						{getText("order-manager.calculate-route.generate-route")}
					</Button>
				)}
				{calculateRouteStep === 2 && (
					<Button onClick={() => setCalculateRouteStep(1)} disabled={routeMapIsLoading}>
						{getText("order-manager.calculate-route.return-route")}
					</Button>
				)}

				<XCloseButton toClose={handleOnClose} />
			</Modal>
			<Backdrop onClick={() => handleOnClose()} />
		</Container>
	) : null;
}
