export function formatMoney(value, symbol) {
	const valueToFormat = value ? value : 0;
	const currentSymbol = symbol && symbol.length > 0 ? symbol : "BRL";

	try {
		return new Intl.NumberFormat("pt-BR", {
			style: "currency",
			currency: currentSymbol,
			maximumFractionDigits: 2,
		}).format(valueToFormat);
	} catch (error) {
		const formattedValue = new Intl.NumberFormat("pt-BR", {
			style: "decimal",
			maximumFractionDigits: 2,
		}).format(valueToFormat);

		return `${currentSymbol} ${formattedValue}`;
	}
}

export function inputMoneyMask(event) {
	let formatValue = event.target.value.replace(/\D/g, "");
	formatValue = (formatValue / 100).toFixed(2);
	formatValue = formatValue.replace(".", ",");
	formatValue = formatValue.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
	return formatValue;
}

export function formatDecimal({value, decimal = 2}) {
	return Number.parseFloat(value || 0).toFixed(decimal)
}

export function formatExcelNumber(value) {
	return String(value).replace(".", ",");
}
