import { BrandingContainer } from "../../components/pagesComponents/userCardRegisterPage/brandingContainer";
import { CardForm } from "../../components/pagesComponents/userCardRegisterPage/cardForm";
import { queryClient } from "../../services/queryClient";
import { useEffect } from "react";
import { Container } from "./style";

export function UserCardRegister() {
	useEffect(() => {
		queryClient.invalidateQueries("cards");
	}, []);

	return (
		<Container>
			<BrandingContainer />
			<CardForm />
		</Container>
	);
}
