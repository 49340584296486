export const esES = {
    "order-manager": {
      "header-title": "Gestor de pedidos",
      "top-bar": {
        "not-delivered": "No entregados",
        "concluded": "Concluidos",
        "scheduled-orders": "Pedidos programados",
        "update": "Actualizar",
        "test-sound": "Probar sonido",
        "open-stores": "Abrir tiendas",
        "manage-stores": "Gestionar tiendas",
        "close-cash-register": "Cerrar caja",
        "links": "Enlaces",
        "calculate-route": "Calcular rutas",
        "change-language": "Cambiar idioma",
      },
      "chose-motoboy": {
        "title": "Elige un motoboy",
        "cancel": "Cancelar",
        "confirm": "Confirmar",
        "select-placeholder": "Selecciona un motoboy...",
      },
      "close-cash-register": {
        "title": "Cierre de caja",
        "close-cash-register": "Cerrar caja",
        "cash-register-closed": "Caja cerrada",
        "close-button": "Concluir",
      },
      "scheduling-modal": {
        "title": "Pedidos programados",
        "empty": "No hay pedidos programados",
      },
      "open-cash-register": {
        "modal-title": "Gestión de caja",
        "open-all-cash-registers": "Abrir todas las cajas",
        "close-button": "Entrar",
        "cash-register-open": "Caja abierta",
        "open-cash-register": "Abrir caja",
      },
      "manage-stores": {
        "store-management": "Gestión de tiendas",
        "manage-cash-registers": "Gestionar cajas",
        "open-all-stores": "Abrir todas las tiendas",
        "close-button": "Confirmar",
      },
      "order-card": {
        "pendent": "Pendiente",
        "preparing": "Preparando",
        "delivering": "Entregando",
      },
      "link-stores": {
        "modal-title": "Enlaces",
        "close-button": "Cerrar",
      },
      "calculate-route": {
        "modal-title": "Calcular rutas",
        "quantity-orders": "Cantidad de pedidos",
        "select-business": "Seleccione una empresa",
        "select-all-business": "Todas las empresas",
        "current-company": "Empresa actual",
        "chose-purchases": "Elige los pedidos",
        "generate-route": "Generar ruta",
        "return-route": "Volver",
        "close-button": "Cerrar",
        "calculating-route": "Calculando ruta...",
        "calculated-routes": "Rutas calculadas",
        "distance": "Distancia",
        "duration": "Duración",
        "address": "Dirección",
        "map-link": "link do google maps",
        "copy": "Copiar",
        "copied": "Copiado",
        "toast": {
          "no-orders-selected": "No se han seleccionado pedidos.",
          "error-generate": "Hubo un error al generar la ruta."
        }
      },
      "chose-language": {
        "modal-title": "Elige tu idioma",
        "pt-br": "Portugués",
        "es-es": "Español",
        "close-button": "Cerrar",
      },
    },
    "shopping": {
      "payment-method": "Método de pago",
      "finish-purchase": "Finalizar pedido",
      "table": "Mesa",
      "withdrawal": "Retirada",
      "subtotal": "Subtotal",
      "total": "Total",
      "finish": "Finalizar",
      "add-more": "Añadir más artículos",
      "delivery": "Entrega",
      "take-away": "Para llevar",
      "delivery-to": "Entregar en",
      "chose-address": "Elige la dirección",
      "take-on": "Recoger en",
      "using-current-location": "Usando ubicación actual",
      "scheduled-to": "Programado para",
      "free": "Gratis",
      "coupon": "Cupón",
      "cashback": "Reembolso",
      "not-buying": "No estás realizando una compra",
      "access": "Acceder",
      "delivery-tax": "Tasa de entrega",
      "thanks-for-participating": "Gracias por participar en el sorteo",
      "draw-day": "El sorteo se realizará el día",
      "good-luck": "¡Buena suerte!",
      "placing-order": "Realizando pedido...",
      "toast": {
        "handleModifyChange": "Cambio insuficiente, ingrese nuevamente",
        "handleSendOrder-error": "Has alcanzado el límite de intentos, inténtalo de nuevo en 5 minutos o selecciona otro método de pago",
        "handleSendOrder-info": "Este cupón no acepta este tipo de pago, por favor selecciona un pago en línea.",
        "handleSendOrder-error-warning": "Tu sesión ha expirado",
        "handleSendOrder-error-1": "Número de mesa inválido",
        "handleSendOrder-error-2": "Comanda inválida",
        "error-1": "Ubicación inválida.",
        "error-2": "Ubicación inválida, verifica la activación de la ubicación de tu dispositivo e inténtalo de nuevo",
        "error-3": "⚠️ Selecciona uno de los productos en las reglas del cupón para validar tu descuento",
        "error-4": "El valor mínimo de compra en este establecimiento es:",
        "error-5": "El establecimiento está cerrado en este momento",
        "error-6": "La empresa no atiende esta dirección",
      },
    },
};
