import { api } from "../../../../services/api";

export async function openStore({ companyId, status, token }) {
	await api.post(
		"/updateLojaAberta",
		{
			empresa: companyId,
			status,
		},
		{ headers: { token } },
	);
}
