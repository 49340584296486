import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  background-color: var(--gray-50);
  border-bottom: 1px solid var(--gray-200);
  gap: 2rem;
  .logo-wrapper {
    display: flex;
    align-items: center;
    gap: 1rem;
    position: relative;
    h1 {
      font-weight: 600;
      font-size: 1.5rem;
    }
    .icon {
      color: var(--brand-500);
      cursor: pointer;
      &:hover {
        opacity: 0.8;
      }
    }
  }
  .wrapper {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    .info-wrapper {
      flex-wrap: wrap;
      gap: 1rem;
    }
    @media (min-width: 1080px) {
      flex-direction: row;
    }
  }
  .info-wrapper {
    flex: 1;
    display: flex;
    justify-content: space-around;
    align-items: center;
    div:nth-child(1),
    div:nth-child(2) {
      cursor: auto;
      &:hover {
        opacity: 1;
      }
      &:active {
        opacity: 1;
      }
    }
    .icon {
      color: var(--brand-500);
      font-size: 2rem;
    }
    .card-container {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      align-items: center;
      font-weight: 500;
      cursor: pointer;
      &:hover {
        opacity: 0.7;
      }
      &:active {
        opacity: 1;
      }
    }
    .card-container :nth-child(1) {
      color: var(--brand-500);
      font-size: 1.5rem;
    }
  }
`;
