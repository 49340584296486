import React from "react";
import inoveImg from "../../../images/logo.png";

import { Container } from "./style";

export function BrandingContainer() {
	//localStorage.getItem('@Inoveclube:imageBusiness')
	const image = localStorage.getItem("@Inoveclube:imageBusiness");

	console.log(image);

	return (
		<Container>
			<img
				src={
					image
						? image.toString().toUpperCase() === "NULL"
							? inoveImg
							: image
						: inoveImg
				}
				alt="imagem logo"
			/>
		</Container>
	);
}
