import { api } from "../../../../services/api";

export async function getAmountOfScheduledOrders({ companiesId, token }) {
	const { data } = await api.get(`/getScheduledOrdersAmount`, {
		headers: { token },
		params: {
			companies: companiesId.join(","),
		},
	});

	return data.scheduledOrdersAmount;
}
