import { createGlobalStyle } from "styled-components";
import "react-toastify/dist/ReactToastify.css";

export default createGlobalStyle`

    :root{
        --colorLabel: ${
					localStorage.getItem("@Inoveclube:colorLabel")
						? `${localStorage.getItem("@Inoveclube:colorLabel")}`
						: "#FFF"
				};
        --colorButton: ${
					localStorage.getItem("@Inoveclube:colorButton")
						? `${localStorage.getItem("@Inoveclube:colorButton")}`
						: "#7A05A7"
				};
        --colorTransversalBar: ${
					localStorage.getItem("@Inoveclube:colorTransversalBar")
						? `${localStorage.getItem("@Inoveclube:colorTransversalBar")}`
						: "#7A05A7"
				}; 
        --colorBorderInput: ${
					localStorage.getItem("@Inoveclube:colorBorderInput")
						? `${localStorage.getItem("@Inoveclube:colorBorderInput")}`
						: "#7A05A7"
				};
        --colorShadow: ${
					localStorage.getItem("@Inoveclube:colorShadow")
						? `${localStorage.getItem("@Inoveclube:colorShadow") + "1A"}`
						: "rgba(0, 0, 0, 0.1)"
				};
        --colorButtonBottom: ${
					localStorage.getItem("@Inoveclube:colorButtonBottom")
						? `${localStorage.getItem("@Inoveclube:colorButtonBottom")}`
						: "#FFF"
				};
        --colorBgHeader: ${
					localStorage.getItem("@Inoveclube:colorBgHeader")
						? `${localStorage.getItem("@Inoveclube:colorBgHeader")}`
						: "#7A05A7"
				};

        --colorBackgroundCardsDashboard: #F0F1F5;
        --colorFontsCardsDashboard: #535354;
        --colorBackgroundSubCardsDashboard: #F5F5F5;
        --dividerBorder: #cbd5e1;
        --mainTheme: #4A007A;
        --barsColor: #4A007A;
        --green-500: #2d939a;
        --green-400: oklch(0.723 0.219 149.579);
        --red-500: #e06059;
        --blue-500: #007AFF;
        --yellow-500: #eab308;

        /* Brand purple Collor Pallet */
        --brand-50: #fbf6fd;
        --brand-100: #f6ecfb;
        --brand-200: #ecd8f6;
        --brand-300: #dfb9ee;
        --brand-400: #cc8fe3;
        --brand-500: #b564d1;
        --brand-600: #9944b5;
        --brand-700: #803695;
        --brand-800: #642b73;
        --brand-900: #5a2a65;
        --brand-950: #381141;

        /* Brand red Collor Pallet */
        --brand-red-50: #fbf6fd;
        --brand-red-100: #f9eaf1;
        --brand-red-200: #f6d4e3;
        --brand-red-300: #efb2cc;
        --brand-red-400: #e482aa;
        --brand-red-500: #d85c8b;
        --brand-red-600: #c6426e;
        --brand-red-700: #a92d52;
        --brand-red-800: #8c2845;
        --brand-red-900: #76253d;
        --brand-red-950: #471020;

        /* Gray Collor Pallet */
        --gray-50: #f9fafb;
        --gray-100: #f3f4f6;
        --gray-200: #e5e7eb;
        --gray-300: #d1d5db;
        --gray-400: #9ca3af;
        --gray-500: #6b7280;
        --gray-600: #4b5563;
        --gray-700: #374151;
        --gray-800: #1f2937;
        --gray-900: #111827;

        --border-radius: 0.5rem;
    }

    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        outline: 0;
    }

    html{
        text-rendering: optimizeLegibility;
        @media (max-width: 1080px){
            font-size: 93.75%;

        }

        @media (max-width: 720px){
            font-size: 87.5%;
            
        }
    }

    body {
        -webkit-font-smoothing: antialiased;   
        user-select: none;

        &::-webkit-scrollbar-track {
            background-color: #FFF;
        }

        &::-webkit-scrollbar {
            width: 5px;
        }

        &::-webkit-scrollbar-thumb {
            background: #C3C3C3;
            border-radius: 4px;
        }

        scrollbar-width: thin;
        scrollbar-color: #C3C3C3;
        scrollbar-track-color: #FFF;

        select {
            -webkit-appearance: none;
            -moz-appearance: none;
            -o-appearance: none;
            appearance: none;
            background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path d="M7 10l5 5 5-5z"/></svg>') no-repeat right 0.5rem center;
            background-size: 1.5rem;
            padding-right: 2rem;
            background-color: #FFF;
        }
    }

    .scroll {
        &::-webkit-scrollbar-track {
            background-color: #FFF;
        }

        &::-webkit-scrollbar {
            width: 5px;
            height: 4px
        }

        &::-webkit-scrollbar-thumb {
            background: #C3C3C3;
            border-radius: 4px;
        }

        scrollbar-width: auto;
        scrollbar-color: #C3C3C3;
        scrollbar-track-color: #FFF;
    }

    body, input, button {
        font-family: 'Ubuntu', sans-serif;
        font-weight: 400;
    }

    button {
        cursor: pointer;
    }

    a {
        color: inherit;
        text-decoration: none;
    }

    [disabled]{
        opacity: 0.6;
        cursor: not-allowed;
    }

    /* Toggle Style */
    .react-toggle {
        touch-action: pan-x;

        display: inline-block;
        position: relative;
        cursor: pointer;
        background-color: transparent;
        border: 0;
        padding: 0;

        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;

        -webkit-tap-highlight-color: rgba(0,0,0,0);
        -webkit-tap-highlight-color: transparent;
        }

        .react-toggle-screenreader-only {
        border: 0;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
        }

        .react-toggle--disabled {
        cursor: not-allowed;
        opacity: 0.5;
        -webkit-transition: opacity 0.25s;
        transition: opacity 0.25s;
        }

        .react-toggle-track {
        width: 50px;
        height: 24px;
        padding: 0;
        border-radius: 30px;
        background-color: #4D4D4D;
        -webkit-transition: all 0.2s ease;
        -moz-transition: all 0.2s ease;
        transition: all 0.2s ease;
        }

        .react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
        background-color: #000000;
        }

        .react-toggle--checked .react-toggle-track {
        background-color: #7A05A7;
        }

        .react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
        background-color: #af69ca;
        }

        .react-toggle-track-check {
        position: absolute;
        width: 14px;
        height: 10px;
        top: 0px;
        bottom: 0px;
        margin-top: auto;
        margin-bottom: auto;
        line-height: 0;
        left: 8px;
        opacity: 0;
        -webkit-transition: opacity 0.25s ease;
        -moz-transition: opacity 0.25s ease;
        transition: opacity 0.25s ease;
        }

        .react-toggle--checked .react-toggle-track-check {
        opacity: 1;
        -webkit-transition: opacity 0.25s ease;
        -moz-transition: opacity 0.25s ease;
        transition: opacity 0.25s ease;
        }

        .react-toggle-track-x {
        position: absolute;
        width: 10px;
        height: 10px;
        top: 0px;
        bottom: 0px;
        margin-top: auto;
        margin-bottom: auto;
        line-height: 0;
        right: 10px;
        opacity: 1;
        -webkit-transition: opacity 0.25s ease;
        -moz-transition: opacity 0.25s ease;
        transition: opacity 0.25s ease;
        }

        .react-toggle--checked .react-toggle-track-x {
        opacity: 0;
        }

        .react-toggle-thumb {
        transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
        position: absolute;
        top: 1px;
        left: 1px;
        width: 22px;
        height: 22px;
        border: 1px solid #4D4D4D;
        border-radius: 50%;
        background-color: #FAFAFA;

        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;

        -webkit-transition: all 0.25s ease;
        -moz-transition: all 0.25s ease;
        transition: all 0.25s ease;
        }

        .react-toggle--checked .react-toggle-thumb {
        left: 27px;
        border-color: #19AB27;
        }

        .react-toggle--focus .react-toggle-thumb {
        -webkit-box-shadow: 0px 0px 3px 2px #ca9bdc;
        -moz-box-shadow: 0px 0px 3px 2px #ca9bdc;
        box-shadow: 0px 0px 2px 3px #ca9bdc;
        }

        .react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
        -webkit-box-shadow: 0px 0px 5px 5px #ca9bdc;
        -moz-box-shadow: 0px 0px 5px 5px #ca9bdc;
        box-shadow: 0px 0px 5px 5px #ca9bdc;
    }

    /* Animations */
    .bounce-in {
        animation: bounceIn 1.5s infinite;

        @keyframes bounceIn {
            0%,
            100% {
                transform: scale(0.9);
                animation-timing-function: cubic-bezier(0, 0, 1, 1);
            }
            50% {
                transform: scale(1.1);
                animation-timing-function: cubic-bezier(0, 0, 1, 1);
            }
        }
    }

    .pulse {
        animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;

        @keyframes pulse {
            0%, 100% {
                opacity: .7;
            }
            50% {
                opacity: .4;
            }
        }
    }
`;
