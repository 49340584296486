import React from "react";
import { Select } from "../../../../../components/ui/base-components";
import { useOrderManagerContext } from "../../../context";
import { InputCheck } from "../../../../../components/Forms/InputCheck";
import { CardContent, CardOrders, CardRouter } from "../style";
import { useTranslator } from "../../../../../context/TranslatorContext";

export default function SelectOrderRoute() {
	const { getText } = useTranslator();

	const {
		orders,
		companies,
		selectedBusiness,
		setSelectedBusiness,
		orderRouterSelected,
		setOrderRouterSelected,
	} = useOrderManagerContext();

	const handleSelectRouter = (order) => {
		const existOrder = orderRouterSelected.find(
			(item) => item.idOrcamento === order.idOrcamento,
		);
		if (existOrder) {
			const newOrderRouter = orderRouterSelected.filter(
				(item) => item.idOrcamento !== order.idOrcamento,
			);
			setOrderRouterSelected(newOrderRouter);
		} else {
			setOrderRouterSelected([...orderRouterSelected, order]);
		}
	};

	return (
		<>
			<div>
				<Select
					value={selectedBusiness}
					onChange={(e) => setSelectedBusiness(e.target.value)}
				>
					<option value={null} disabled>
						{getText("order-manager.calculate-route.select-business")}
					</option>
					<option value="all">
						{getText("order-manager.calculate-route.select-all-business")}
					</option>
					{companies
						?.filter((item) => item.selected)
						.map((item) => {
							return (
								<option key={item.cdEmpresa} value={item.cdEmpresa}>
									{item.dsEmpresa}
								</option>
							);
						})}
				</Select>
			</div>

			<div>
				{selectedBusiness === "all" && (
					<>
						<p>{getText("order-manager.calculate-route.current-company")}</p>
						<p>{companies.filter((company) => company.selected)[0].dsEmpresa}</p>
						<br />
					</>
				)}
				<p>{getText("order-manager.calculate-route.chose-purchases")}</p>
				<CardOrders>
					{selectedBusiness === "all" &&
						orders
							?.filter((item) => item?.dsStatus === "E" && item?.tipoPedido === "D")
							.map((item) => {
								return (
									<CardRouter key={item.idOrcamento}>
										<div>
											<InputCheck
												checked={orderRouterSelected.some(
													(order) => order.idOrcamento === item.idOrcamento,
												)}
												handleClick={() => handleSelectRouter(item)}
											/>
										</div>
										<CardContent>
											<span style={{ fontWeight: "bold" }}>ID: {item.idOrcamento}</span>
											<span>{item.nmPessoa}</span>
											<span>{item.dsBairro}</span>
										</CardContent>
									</CardRouter>
								);
							})}
					{orders
						?.filter(
							(item) =>
								item?.dsStatus === "E" &&
								item?.tipoPedido === "D" &&
								String(item?.cdEmpresa) === selectedBusiness,
						)
						.map((item) => {
							return (
								<CardRouter key={item.idOrcamento}>
									<div>
										<InputCheck
											checked={orderRouterSelected.some(
												(order) => order.idOrcamento === item.idOrcamento,
											)}
											handleClick={() => handleSelectRouter(item)}
										/>
									</div>
									<CardContent>
										<span style={{ fontWeight: "bold" }}>ID: {item.idOrcamento}</span>
										<span>{item.nmPessoa}</span>
										<span>{item.dsBairro}</span>
									</CardContent>
								</CardRouter>
							);
						})}
				</CardOrders>
			</div>
			<div>
				<p>
					{getText("order-manager.calculate-route.quantity-orders")}:{" "}
					{orderRouterSelected.length}
				</p>
			</div>
		</>
	);
}
